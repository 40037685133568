import { timeFormat, numberFormat } from "@custom/index";
export function apartmentColumn(_this) {
    return [
        {
            type: "selection",
            width: 55,
            fixed: 'left'
        },
        {
            type: "index",
            label: "序号",
            width: 80,
            fixed: 'left'
        },
        {
            prop: "name",
            label: "地址",
            fixed: 'left',
            render: (h, {row}) => {
                const {name} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        {
            prop: "propertyCode",
            label: "房源类型",
            fixed: 'left',
            render: (h, { row }) => {
                const propertyCode =_this.propertyCodeList.find(v => v.code === row.propertyCode);
                // 2024/02/27   张晓瑜需改保障性住房为保障性租赁住房(暂时)
                const codeName = propertyCode ? ((propertyCode.code.toString()).startsWith("60021")?"保障性租赁住房:":"长租公寓:")+ propertyCode.codeName : "--";
                return h("span", (codeName));
            },
        },
        {
            prop: "apartmentType",
            label: "户型",
            width: 100
        },
        {
            prop: "updateTime",
            label: "发布时间",
            render: (h, { row }) => {
                return timeFormat(row.updateTime?new Date(row.updateTime):"");
            }
        }
        /*{
            prop: "publishArea",
            label: "面积",
            render: (h, { row }) => {
                return h("span", row.publishArea?row.publishArea+"㎡":"0");
            },
            width: 100
        },*/
    ];
}